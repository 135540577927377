import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, PrivateRoute } from '../components'
import { fetchData } from '../services/api';
import { parseACF } from '../utils'

import Home from './index'
import projectsSingle from '../templates/projects-single'
import DefaultPage from '../templates/default-page'

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
    const postID = useSearchParam('id')
	const graphQuery = useStaticQuery(query)
	const projectsData = graphQuery?.allWordpressProjects?.nodes
    
	useMount(() => {
		fetchData(`/hub/preview/${postID}`, true, true)
			.then(response => {		    	
				let data = response;
				setData(data)
				setPostType(data.post_type)
				// console.log('data', data)
				// console.log(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			'home': Home,
			'projects': projectsSingle,
			'default': DefaultPage
		}

        const Component = templates[postType];

		return (
			<Component
				pageContext={{
					data: {
						...data,
						...data.acf,
						projects: projectsData
					}
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export const query = graphql`
    query {
		allWordpressProjects {
			nodes {
				title
				slug
				wordpress_id
				acf {
					thumbnail_text
					thumbnail_image {
						url
						sizes {
							medium2
						}
					}
				}
			}
		}
    }
`

export default PreviewRouter

