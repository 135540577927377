import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { getLastTextIndexes } from '../utils'
import { use100vh } from 'react-div-100vh'

import { Layout, PageHeader, Hero, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button } from '../styles/global'
import { headerHeight } from '../components/Header/Header'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'

const DefaultPage = props => {
    const data = props?.pageContext?.data
    const projects_data = data?.projects
    const screenHeight = use100vh()
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    // console.log(data)

    useMount(() => {
        setPageTitle(data?.title)
    })

	const renderBlocks = () => {
        if (!data?.content_blocks) return;

		return data.content_blocks.map((block, i) => {
            // if (block?.acf_fc_layout !== 'projects_block') return false
			return (
				<Block
                    key={i}
                    firstBlock={i === 0}
                    lastBlock={i+1 === data.content_blocks.length}
                    projects_data={projects_data}
					{...block}
                />
			)
		})
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <HeroSizer
                    style={{
                        height: screenHeight - 80,
                    }}
                >
                    <Hero
                        media_type={data?.hero_media_type}
                        image={data?.hero_image}
                        video={data?.hero_video}
                        content={data?.hero_content}
                    />
                </HeroSizer>
                {renderBlocks()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled.div``
const ExternalLink = styled.a``
const Button = styled.div`
    ${button}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};

    .hero-section {
        ${tw`border-b border-darkGrey`};
    }
`

const Container = styled.div`
    ${container};
    ${padding};
`

const HeroSizer = styled.div`
    ${tw`relative`};
`


export default DefaultPage
