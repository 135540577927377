import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { use100vh } from 'react-div-100vh'

import { MobileMenuContext } from '../components/MobileMenu/context'

import { Hero } from '../components'
import { parseACF, renderLink } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'

const Index = (props) => {
    const data = props?.pageContext?.data || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const mobileMenu = useContext(MobileMenuContext)
    const [ heroMedia, setHeroMedia ] = useState(false)
    // console.log(heroMedia)

    const getRandomInt = max => {
        return Math.floor(Math.random() * max)
    }

    useEffect(() => {
        const randomInt = getRandomInt(data?.hero_media?.length)
        if (!heroMedia) {
            setHeroMedia(data?.hero_media?.[randomInt])
        }
    }, [])

    return (
        <Layout
            meta={data && data.seo}
            isHome={true}
        >
            <Wrapper
                onClick={() => {
                    mobileMenu.toggleActive()
                }}
            >
                {heroMedia && (
                    <Hero
                        media_type={heroMedia?.hero_media_type}
                        image={heroMedia?.hero_image}
                        video={heroMedia?.hero_video}
                        content={data?.hero_content}
                        largeText={true}
                    />
                )}
            </Wrapper>
        </Layout>
    )
}

const Heading = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
`

const Wrapper = styled.div`
    ${tw`fixed inset-0`};
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
